import { Component, OnInit, Input, OnDestroy, Output, EventEmitter, } from '@angular/core'
import { Schedule } from '@app/shared'
import { Store } from '@ngrx/store'
import { Subject, takeUntil, tap } from 'rxjs'
import * as fromCoreStore from '@app/core/store'
import { OpenModal } from '@app/modals/store'
import moment from 'moment'

@Component({
  selector: 'app-schedule-list',
  templateUrl: './schedule-list.component.html',
  styleUrls: ['./schedule-list.component.scss'],
})
export class ScheduleListComponent implements OnInit, OnDestroy {
  @Input()
  schedules: Schedule[]
  @Input()
  filters
  @Input()
  total
  @Input()
  currentUser
  @Input()
  equipments
  @Output()
  onUpdate = new EventEmitter<any>()
  limit = 25

  destroyed$ = new Subject<boolean>()

  constructor(private store: Store<fromCoreStore.State>,) { }

  ngOnInit() {

    this.store.select(fromCoreStore.selectScheduleFilter).pipe(
      takeUntil(this.destroyed$),
      tap(filters => {
        if (filters?.type == 'DAY OFF') {
          filters['type'] = 'DAY_OFF'
        }

        if (!filters?.subType) {
          filters['subType'] = 'Drivers'
        }

        this.filters = filters

        let f = {
          ...filters
        }

        if (f?.rangeType) {
          delete f.rangeType
        }

        if (f?.driver) {
          f['driverId'] = f?.driver?._id
          delete f.driver
        }

        if (f?.unit) {
          f['unitId'] = f?.unit?._id
          delete f.unit
        }

        if (f?.dispatcher) {
          f['dispatchId'] = f?.dispatcher?._id
          delete f.dispatcher
        }

        this.store.dispatch(new fromCoreStore.GetSchedules(f))
      })
    ).subscribe()
  }

  openViewRequest(schedule) {
    this.store.dispatch(
      new OpenModal({
        type: 'VIEW_REQUEST',
        props: {
          windowClass: "view-request-modal",
          schedule,
          equipments: this.equipments,
          currentUser: this.currentUser,
          filters: this.filters
        },
      })
    )
  }

  onSort(event) {

  }

  ngOnDestroy() {
    this.destroyed$.next(true)
    this.destroyed$.complete()
  }
}



